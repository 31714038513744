import {
  BadgeType,
  DeliveryTimeCode,
  TSrpProductType,
} from "../../dto/searchResults.types";

export const SEARCH_PRODUCTS_V2 = [
  {
    sku: "1001000171",
    associations: { TestLabel: ["1001000145"] },
    type: TSrpProductType.PRODUCT,
    conceptId: null,

    badges: [
      {
        id: 4,
        name: "BestsellerBadgeTest",
        badgeKind: BadgeType.BESTSELLER,
      },
      {
        id: 6,
        name: "PromotionBadgeTest",
        badgeKind: BadgeType.PROMOTION,
      },
      {
        id: 5,
        name: "New",
        badgeKind: BadgeType.NEW,
      },
    ],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "LENOR Sensitive, płyn do płukania, 1360 ml",
    basePrice: 16.3,
    promotionPrice: null,
    netPrice: 10,
    grossPrice: 12,
    omnibusPrice: 10,
    tax: 50,
    rating: {
      numberOfReviews: 322,
      rate: 1.4,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    pools: [{ id: 1, name: "PulaTestowa" }],
    photoGallery: [
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
    ],
    wishlist: { base: true, concept: false },
    axis: "Test4",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Variant1",
          label: "Variant1",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
    ],
    Brand: {
      value: "asd",
      label: "Ariel",
    },
    BasePrice: {
      value: "19.2300",
      label: null,
    },
    CompletionInformation: {
      value: "asssd",
      label:
        "Attribute PortalProducer is empty\nAttribute PortalLongName is empty",
    },
    MaterialID: {
      value: "asssdsss",
      label: "1001001199",
    },

    Producer: {
      value: "assasdassd",
      label: "A20",
    },
    DeliveryQuantity: {
      value: "sssdd",
      label: "4",
    },
    PortalDisplayOrder: {
      value: "ddd",
      label: "9999",
    },
    FullName: {
      value: "asffffssd",
      label: "ARIEL Płyn Touch of Lenor 2,64L",
    },
    country_of_origin: {
      value: "fffff",
      label: "PCE",
    },
    ItemCategoryGroup: {
      value: "gggggg",
      label: "Płyny do prania",
    },
    BaseUnitOfMeasure: {
      value: "hhhhh",
      label: "NORM",
    },
    Stock: {
      value: "jjjjj",
      label: "878.0000",
    },
  },
  {
    sku: "1001000145",
    type: TSrpProductType.PRODUCT,
    conceptId: null,

    badges: [
      {
        id: 6,
        name: "Promotion",
        badgeKind: BadgeType.PROMOTION,
      },
      {
        id: 5,
        name: "New",
        badgeKind: BadgeType.NEW,
      },
    ],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/e/b/7/9/eb794c1ed58ca6ea840067437eb09b57e2e31705_PL_8001090206930_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "LENOR Sensitive, płyn do płukania, 930 ml",
    basePrice: 9.7,
    promotionPrice: null,
    netPrice: 10,
    grossPrice: 12,
    omnibusPrice: 10,
    tax: 50,
    rating: {
      numberOfReviews: 0,
      rate: null,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    wishlist: { base: false, concept: false },
    axis: "Brand",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Brand",
          label: "Brand",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Belutti",
          label: "Belutti",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
    ],

    associations: {},
  },
  {
    sku: "1001000685",
    conceptId: null,
    type: TSrpProductType.PRODUCT,

    badges: [],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/d/3/1/5/d3150984a5cf89a5861550219032b8879e2bfb34_PL_8001090791313_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "ARIEL Sensitive, płyn do prania, 2.2 l",
    basePrice: 39.66,
    promotionPrice: null,
    netPrice: 10,
    grossPrice: 12,
    omnibusPrice: 10,
    tax: 50,
    rating: {
      numberOfReviews: 0,
      rate: null,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    wishlist: { base: true, concept: false },
    axis: "Brand",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Brand",
          label: "Brand",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Belutti",
          label: "Belutti",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
    ],

    associations: {},
  },
  {
    sku: "1001001059",
    conceptId: null,
    type: TSrpProductType.PRODUCT,

    badges: [],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/a/3/9/0a39bc050d6e34b1b8a37e32b5447ba89a19b822_PL_8001841163666_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "LENOR Sensitive, płyn do płukania, 2.91 l",
    basePrice: 24.81,
    promotionPrice: null,
    netPrice: 10,
    grossPrice: 12,
    omnibusPrice: 10,
    tax: 50,
    rating: {
      numberOfReviews: 0,
      rate: null,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    wishlist: { base: true, concept: false },
    axis: "Brand",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Brand",
          label: "Brand",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Belutti",
          label: "Belutti",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
    ],

    associations: {},
  },
];
