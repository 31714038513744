import {
  CreditLimitType,
  ICartB2B_DTO,
  LimitedClientStatus,
  NotificationErrorB2B_Cart,
  PaymentMethodOptionB2B,
} from "../../../dto/cartB2B.types";

export const ecpCartB2BResponse_basic: ICartB2B_DTO = {
  hasNotificationErrors: false,
  cartId: "cartId",
  orders: [
    {
      productType: "productType AAA",
      netLogisticMin: 150,
      netLogisticMinMissing: 75,
      deliveryCalendar: [
        {
          deliveryDate: "2024-04-26",
          enabled: true,
        },
        {
          deliveryDate: "2024-04-30",
          enabled: true,
        },
        {
          deliveryDate: "2024-05-05",
          enabled: true,
        },
      ],
      deliveryDate: "2024-04-26",
      cartLines: [
        {
          sku: "1011000584",
          name: "AKCESORIA 3247, Accessories, Size2246",
          photoUrl:
            "https://dev-minio-ecp.nd0.pl/ecp-media-cms/rachit-tank.jpg",
          productUrl: "/p/anna-karenina-i978837000020",
          quantity: 7,
          deliveryQuantity: 4,
          stock: 133,
          unitOfMeasure: {
            currentUnitOfMeasure: "PCE",
            baseUnitOfMeasure: "PCE",
            availableUnitsOfMeasure: [
              {
                unitOfMeasure: "PCE",
                unitOfMeasureQuantity: 1,
                unitOfMeasureLabel: "Sztuka",
              },
              {
                unitOfMeasure: "CT",
                unitOfMeasureQuantity: 320,
                unitOfMeasureLabel: "Karton",
              },
            ],
          },
          productPriceDetails: {
            netValue: 125.74,
            grossValue: 154.66,
          },
          notificationErrors: [
            {
              type: NotificationErrorB2B_Cart.CREDIT_LIMIT_IS_NOT_ENABLED,
              data: "aaa",
            },
            {
              type: NotificationErrorB2B_Cart.PRODUCT_IS_NOT_AVAILABILITY,
              data: "aaa",
            },
          ],
        },
        {
          sku: "2011000584",
          name: "Krewetka złowiona przez Forresta Gumpa",
          photoUrl:
            "https://dev-minio-ecp.nd0.pl/ecp-media-cms/rachit-tank.jpg",
          productUrl: "/p/anna-karenina-i978837000020",
          quantity: 7,
          deliveryQuantity: 4,
          stock: 133,
          unitOfMeasure: {
            currentUnitOfMeasure: "PCE",
            baseUnitOfMeasure: "PCE",
            availableUnitsOfMeasure: [
              {
                unitOfMeasure: "PCE",
                unitOfMeasureQuantity: 1,
                unitOfMeasureLabel: "Sztuka",
              },
              {
                unitOfMeasure: "CT",
                unitOfMeasureQuantity: 320,
                unitOfMeasureLabel: "Karton",
              },
            ],
          },
          productPriceDetails: {
            netValue: 125.74,
            grossValue: 154.66,
          },
          notificationErrors: [],
        },
        {
          sku: "20110005842222",
          name: "Zdjęcie z tłem przedstawiające ultranowoczesny smartwatch 5G. Dostęp do płatności NFC, eSIM oraz pulsyksometr",
          photoUrl:
            "https://dev-minio-ecp.nd0.pl/ecp-media-cms/rachit-tank.jpg",
          productUrl: "/p/anna-karenina-i978837000020",
          quantity: 7,
          deliveryQuantity: 4,
          stock: 133,
          unitOfMeasure: {
            currentUnitOfMeasure: "PCE",
            baseUnitOfMeasure: "PCE",
            availableUnitsOfMeasure: [
              {
                unitOfMeasure: "PCE",
                unitOfMeasureQuantity: 1,
                unitOfMeasureLabel: "Sztuka",
              },
              {
                unitOfMeasure: "CT",
                unitOfMeasureQuantity: 320,
                unitOfMeasureLabel: "Karton",
              },
            ],
          },
          productPriceDetails: {
            netValue: 125.74,
            grossValue: 154.66,
          },
          notificationErrors: [],
        },
      ],
    },
  ],
  summary: {
    netTotalCartValue: 3291.87,
    grossTotalCartValue: 4040.01,
    creditLimitAvailable: 40000,
    creditLimitExceeded: 0,
    totalCreditLimit: 0,
    creditLimitType: CreditLimitType.NET,
    paymentMethodOptions: [
      {
        active: true,
        portalPaymentMethod: PaymentMethodOptionB2B.CASH,
      },
      {
        active: false,
        portalPaymentMethod: PaymentMethodOptionB2B.CREDIT,
      },
    ],
    deliveryAddress: {
      name: "asd",
      apartmentNo: "xyz",
      city: "xyz",
      country: "xyz",
      houseNo: "xyz",
      postCode: "xyz",
      province: "xyz",
      street: "xyz",
    },
    paymentMethod: PaymentMethodOptionB2B.CASH,
    limitedClientStatus: LimitedClientStatus.LIMITED,
  },
  notificationErrors: [
    {
      data: "aaa",
      type: NotificationErrorB2B_Cart.PRODUCT_IS_NOT_AVAILABILITY,
    },
    {
      data: "aaa",
      type: NotificationErrorB2B_Cart.CONTRACTOR_HAS_NO_DELIVERY_DATE_AVAILABLE,
    },
    {
      data: "aaa",
      type: NotificationErrorB2B_Cart.CONTRACTOR_STATUS_LIMITED,
    },
    {
      data: "aaa",
      type: NotificationErrorB2B_Cart.CREDIT_LIMIT_IS_NOT_ENABLED,
    },
    {
      data: "aaa",
      type: NotificationErrorB2B_Cart.PRODUCT_IS_PARTIALLY_AVAILABILITY,
    },
  ],
  stockVerification: false,
};

export const ecpCartB2BResponsePut: ICartB2B_DTO = {
  hasNotificationErrors: false,
  cartId: "cartId",
  orders: [
    {
      productType: "productType AAA",
      netLogisticMin: 150,
      netLogisticMinMissing: 100,
      deliveryCalendar: [
        {
          deliveryDate: "2024-04-15",
          enabled: true,
        },
        {
          deliveryDate: "2024-04-23",
          enabled: false,
        },
      ],
      deliveryDate: "2024-04-15",
      cartLines: [
        {
          sku: "1011000584",
          name: "Response po DELETE PRODUCT",
          photoUrl: "https://",
          productUrl: "/p/anna-karenina-i978837000020",
          quantity: 50,
          deliveryQuantity: 4,
          stock: 133,
          unitOfMeasure: {
            currentUnitOfMeasure: "PCE",
            baseUnitOfMeasure: "PCE",
            availableUnitsOfMeasure: [
              {
                unitOfMeasure: "PCE",
                unitOfMeasureQuantity: 1,
                unitOfMeasureLabel: "Sztuka",
              },
              {
                unitOfMeasure: "CT",
                unitOfMeasureQuantity: 320,
                unitOfMeasureLabel: "Karton",
              },
            ],
          },
          productPriceDetails: {
            netValue: 125.74,
            grossValue: 154.66,
          },
          notificationErrors: [],
        },
      ],
    },
  ],
  summary: {
    netTotalCartValue: 50000,
    grossTotalCartValue: 70000,
    creditLimitAvailable: 0,
    creditLimitExceeded: 0,
    totalCreditLimit: 0,
    creditLimitType: CreditLimitType.NET,
    paymentMethodOptions: [
      {
        active: true,
        portalPaymentMethod: PaymentMethodOptionB2B.CASH,
      },
    ],
    deliveryAddress: {
      name: "asd",
      apartmentNo: "xyz",
      city: "xyz",
      country: "xyz",
      houseNo: "xyz",
      postCode: "xyz",
      province: "xyz",
      street: "xyz",
    },
    paymentMethod: PaymentMethodOptionB2B.CASH,
    limitedClientStatus: LimitedClientStatus.LIMITED,
  },
  notificationErrors: [],
  stockVerification: false,
};
