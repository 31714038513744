import { AppProps } from "next/app";
import Head from "next/head";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { portalStoreWrapper } from "@ecp-boxes/redux/store";
import { StyledPortalToastContainer } from "@ecp-boxes/shared/components/Snackbar/Snackbar.styled";
import useIsMobilePortal from "@ecp-boxes/shared/hooks/useIsMobilePortal";
import useSetTenant from "@ecp-boxes/shared/hooks/useSetTenant";
import * as theme from "@ecp-redux/api/themeSettings";
import "../styles/globals.css";
import 'react-toastify/dist/ReactToastify.css';

async function initMocks() {
  if (typeof window === "undefined" || process.env.NODE_ENV === "test") {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    const { server } = await import("@ecp-redux/mocks/msw/server");
    server !== undefined && server.listen();
  } else {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    const { worker } = await import("@ecp-redux/mocks/msw/browser");
    worker !== undefined &&
      worker.start({
        onUnhandledRequest: "bypass",
      });
  }
}

if (process.env.NODE_ENV !== "production") {
  initMocks();
}

const GTMScript: React.FC = (): JSX.Element => {
  const isMobile = useIsMobilePortal();
  const { data: themeSettings } = theme.useGetThemeSettingsPortalQuery(
    isMobile ? "MOBILE" : "DESKTOP"
  );
  const customGTMScript = themeSettings?.advanceSettings?.trackingScript;
  return (
    <>
      {customGTMScript && (
        <Head>
          <script
            nonce={process.env.NEXT_PUBLIC_CSP_NONCE}
            id="Google Task Manager"
            dangerouslySetInnerHTML={{
              __html: customGTMScript,
            }}
          />
        </Head>
      )}
    </>
  );
};

const CustomFavicon: React.FC = (): JSX.Element => {
  const isMobile = useIsMobilePortal();
  const { data: themeSettings } = theme.useGetThemeSettingsPortalQuery(
    isMobile ? "MOBILE" : "DESKTOP"
  );

  const customFaviconUrl = themeSettings?.advanceSettings.settings.faviconURL;

  return (
    <>
      <Head>
        <link rel="icon" href={customFaviconUrl} />
      </Head>
    </>
  );
};

function CustomApp({ Component, ...rest }: AppProps) {
  useSetTenant();
  const { store, props } = portalStoreWrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <GTMScript />
      <CustomFavicon />
      <Component {...props.pageProps} />
      <StyledPortalToastContainer
        enableMultiContainer
        containerId="PortalSnackbar"
        position={toast.POSITION.BOTTOM_RIGHT}
        closeButton={false}
        limit={3}
      />
    </Provider>
  );
}

export default CustomApp;
